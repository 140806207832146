<template>
  <div class="wrapper contact-page">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/img/header.jpg')"
      />
      <div class="content-center">
        <div class="container">
          <TypingTitle :titles="['Contact Us...', 'We’d Love to Hear from You']" />
        </div>
      </div>
    </div>
    <div class="main">
      <div class="contact-content">
        <div class="container">
          <h2 class="title">
            Want to work with us?
          </h2>
          <div class="row">
            <!-- Contact Form Section -->
            <div class="col-md-5 mr-auto ml-auto">
              <form
                id="contact-form"
                role="form"
                @submit.prevent="handleSubmit"
              >
                <label for="name">Your name</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="now-ui-icons users_circle-08" />
                    </span>
                  </div>
                  <input
                    id="name"
                    v-model="formData.name"
                    type="text"
                    name="name"
                    placeholder="Your Name..."
                    class="form-control"
                    required
                  >
                </div>
                <span
                  v-if="errors.name"
                  class="error"
                >{{ errors.name }}</span>

                <label for="email">Email address</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="now-ui-icons ui-1_email-85" />
                    </span>
                  </div>
                  <input
                    id="email"
                    v-model="formData.email"
                    type="email"
                    name="email"
                    placeholder="Email Here..."
                    class="form-control"
                    required
                  >
                </div>
                <span
                  v-if="errors.email"
                  class="error"
                >{{ errors.email }}</span>

                <label for="subject">Subject</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="now-ui-icons ui-1_email-85" />
                    </span>
                  </div>
                  <input
                    id="subject"
                    v-model="formData.subject"
                    type="text"
                    name="subject"
                    placeholder="Subject Here..."
                    class="form-control"
                    required
                  >
                </div>
                <span
                  v-if="errors.subject"
                  class="error"
                >{{ errors.subject }}</span>

                <div class="form-group">
                  <label for="message">Your message</label>
                  <textarea
                    id="message"
                    v-model="formData.message"
                    name="message"
                    class="form-control"
                    rows="6"
                    required
                  />
                </div>
                <span
                  v-if="errors.message"
                  class="error"
                >{{ errors.message }}</span>

                <div class="submit text-center">
                  <button
                    type="submit"
                    class="btn btn-primary btn-round"
                    name="submit"
                  >
                    Contact Us
                  </button>
                </div>
              </form>
            </div>

            <!-- Contact Information Section -->
            <div class="col-md-5 ml-auto mr-auto">
              <info-section
                type="primary"
                class="mt-1"
                icon="now-ui-icons location_pin"
              >
                <h4 class="info-title">
                  Find us at the office
                </h4>
                <p>
                  Plot 45, South End Properties, Off Simon Mwansa Kapwepwe Rd,
                  <br>Lusaka,
                  <br>Zambia
                </p>
              </info-section>

              <info-section
                type="primary"
                icon="now-ui-icons tech_mobile"
              >
                <h4 class="info-title">
                  Give us a ring
                </h4>
                <p>
                  +260 974 192 482
                  <br>Mon to Fri 8am to 5 pm
                </p>
              </info-section>

              <info-section
                type="primary"
                icon="now-ui-icons ui-1_email-85"
              >
                <h4 class="info-title">
                  Send us your query anytime!
                </h4>
                <p>rikki@seusconsult.com</p>
              </info-section>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Success Modal -->
    <div
      v-show="showSuccessModal"
      class="modal"
    >
      <div class="modal-content">
        <span
          class="close"
          @click="closeModal"
        >×</span>
        <p>Thank you for the message. We will contact you shortly.</p>
      </div>
    </div>

    <!-- Error Modal -->
    <div
      v-show="showErrorModal"
      class="modal"
    >
      <div class="modal-content">
        <span
          class="close"
          @click="closeModal"
        >×</span>
        <p>Sorry! Please try again.<br>Error: {{ errorMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {FormGroupInput, InfoSection} from "@/components";
import TypingTitle from '@/components/TypingTitle';

export default {
  name: 'ContactUs',
  bodyClass: 'contact-page',
  components: {
    InfoSection,
    [FormGroupInput.name]: FormGroupInput,
    TypingTitle
  },
  data() {
    return {
      formData: {
        name: '',
        email: '',
        subject: '',
        message: ''
      },
      errors: {},
      showSuccessModal: false,
      showErrorModal: false,
      errorMessage: ''
    };
  },
  methods: {
    validateForm() {
      this.errors = {}; // Reset errors
      let isValid = true;

      // Name validation
      if (!this.formData.name) {
        this.errors.name = 'Name is required.';
        isValid = false;
      }

      // Email validation
      if (!this.formData.email) {
        this.errors.email = 'Email is required.';
        isValid = false;
      } else if (!this.validEmail(this.formData.email)) {
        this.errors.email = 'Please enter a valid email address.';
        isValid = false;
      }

      // Subject validation
      if (!this.formData.subject) {
        this.errors.subject = 'Subject is required.';
        isValid = false;
      }

      // Message validation
      if (!this.formData.message) {
        this.errors.message = 'Message is required.';
        isValid = false;
      }

      return isValid;
    },
    validEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email validation
      return re.test(email);
    },
    handleSubmit() {
      if (this.validateForm()) {
        // Proceed with form submission
        const formData = new FormData();
        formData.append('name', this.formData.name);
        formData.append('email', this.formData.email);
        formData.append('subject', this.formData.subject);
        formData.append('message', this.formData.message);

        const xhr = new XMLHttpRequest();
        xhr.open("POST", "/mail2.php", true);
        xhr.setRequestHeader("Accept", "application/json");

        xhr.onload = () => {
          if (xhr.status === 200) {
            try {
              const response = JSON.parse(xhr.responseText);
              if (response.success) {
                this.showSuccessModal = true; // Show success modal
                this.resetForm(); // Clear the form fields
                // Auto-close success modal after 5 seconds
                setTimeout(() => {
                  this.showSuccessModal = false; // Auto-close after 5 seconds
                }, 5000);
              } else {
                this.errorMessage = response.error || "Failed to send message.";
                this.showErrorModal = true; // Show error modal
              }
            } catch (e) {
              this.errorMessage = "Unexpected response format.";
              this.showErrorModal = true; // Show error modal
              this.resetForm();
            }
          } else {
            this.errorMessage = "Failed to send message. Please try again.";
            this.resetForm();
            this.showErrorModal = true; // Show error modal
          }
        };

        xhr.onerror = () => {
          this.errorMessage = "An error occurred. Please check your internet connection and try again.";
          this.showErrorModal = true;
        };

        // Send the form data
        xhr.send(formData);
      }
    },
    resetForm() {
      this.formData = { name: '', email: '', subject: '', message: '' };
      this.errors = {};
    },
    closeModal() {
      this.showSuccessModal = false;
      this.showErrorModal = false;
    }
  }
};
</script>

<style>


/* Error Message Styles */
.error {
  color: red;
  font-size: 0.85em;
  margin-top: 5px;
}

/* Basic Modal Styling */
.modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-content {
  background-color: #fff;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 400px;
  text-align: center;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}
</style>